"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TournamentPlayer = void 0;
var types_1 = require("./types");
var TournamentPlayer = /** @class */ (function () {
    function TournamentPlayer(inTournamentPlayerData) {
        this.tournamentPlayerData = inTournamentPlayerData;
    }
    TournamentPlayer.prototype.hasMissedCut = function () {
        return (this.tournamentPlayerData.status & types_1.GroupPlayerStatus.missed_cut) == types_1.GroupPlayerStatus.missed_cut;
    };
    TournamentPlayer.prototype.isDQ = function () {
        return (this.tournamentPlayerData.status & types_1.GroupPlayerStatus.disqualified) == types_1.GroupPlayerStatus.disqualified;
    };
    TournamentPlayer.prototype.getScoreForRoundAndHole = function (roundIndex, scoreIndex) {
        for (var i = 0; i < this.tournamentPlayerData.rounds[roundIndex].scores.length; ++i) {
            if (this.tournamentPlayerData.rounds[roundIndex].scores[i].hole == scoreIndex + 1) {
                return this.tournamentPlayerData.rounds[roundIndex].scores[i];
            }
        }
        return null;
    };
    return TournamentPlayer;
}());
exports.TournamentPlayer = TournamentPlayer;

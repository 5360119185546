"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupPlayerStatus = void 0;
var GroupPlayerStatus;
(function (GroupPlayerStatus) {
    GroupPlayerStatus[GroupPlayerStatus["undefined"] = 0] = "undefined";
    GroupPlayerStatus[GroupPlayerStatus["missed_cut"] = 1] = "missed_cut";
    GroupPlayerStatus[GroupPlayerStatus["pending_permission"] = 2] = "pending_permission";
    GroupPlayerStatus[GroupPlayerStatus["disqualified"] = 4] = "disqualified";
    GroupPlayerStatus[GroupPlayerStatus["withdrawn"] = 8] = "withdrawn";
    GroupPlayerStatus[GroupPlayerStatus["active"] = 16] = "active";
})(GroupPlayerStatus = exports.GroupPlayerStatus || (exports.GroupPlayerStatus = {}));
